/*@import '~antd/dist/antd.less';*/
@import "assets/css/public.css";
.App {
  /* text-align: center; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.float-left {
  float: left;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-descriptions-title {
  overflow: visible !important;
}
body {
  width: 100%;
  height: 100%;
}
#root,
.full,
.App,
html,
body {
  width: 100%;
  height: 100%;
}
#root,
#root > .App,
#root > .App > div {
  position: relative;
}
.text-danger {
  color: #fd5451;
}
.yuan::before {
  content: "￥";
  margin-right: 5px;
}
iframe {
  z-index: -1 !important;
}
.basicLayoutCard {
  border: none;
  padding: 0 30px;
}
.basicLayoutCard .ant-card-head {
  border: none;
  padding: 0;
  min-height: 0;
  margin-bottom: 20px;
}
.basicLayoutCard .ant-card-head .ant-card-head-wrapper {
  min-height: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.basicLayoutCard .ant-card-head .ant-card-head-title {
  padding: 0;
}
.basicLayoutCard .ant-card-head .ant-card-head-title .title,
.basicLayoutCard .ant-card-head .ant-card-head-title .title a,
.basicLayoutCard .ant-card-head .ant-card-head-title .title > span {
  color: #333;
  font-size: 18px;
}
.basicLayoutCard .ant-card-head .ant-card-extra {
  padding: 0;
}
.basicLayoutCard .ant-card-body {
  padding: 0;
}
.ant-btn-action {
  border-radius: 100%;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 32px;
  color: #3a9afa !important;
  background: transparent !important;
}
.ant-btn-action:hover {
  background: #e3effc !important;
}
.action-menu .ant-dropdown-menu {
  padding: 5px;
}
.action-menu .ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 5px;
  padding: 6px 20px;
}
.action-menu .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-item-icon {
  margin-right: 15px;
}
.action-menu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.action-menu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
  background-color: #f2f3f4;
}
.text-white {
  color: #fff;
}
.text-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.ant-table-thead th.ant-table-cell {
  font-size: 12px;
  color: #7b7b7b;
}
.ant-table-tbody td.ant-table-cell {
  font-size: 12px;
}
.ant-btn.modal-del {
  background-color: #fd5451;
  border-color: #fd5451;
}
.choose-box {
  margin-bottom: 15px;
}
.choose-box .checkbox-group {
  width: 100%;
}
.choose-box .choose-box-item {
  border: 1px dashed #ddd;
  padding: 30px 15px;
  width: 100%;
  margin-bottom: 20px;
}
.choose-box .choose-box-item:hover {
  border: 1px solid cornflowerblue;
}
.choose-box .choose-box-item.full {
  position: fixed;
  background-color: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.choose-box .choose-box-item.full iframe,
.choose-box .choose-box-item.full .showLayout {
  height: 100%;
}
.choose-box .choose-box-item .ant-row {
  justify-content: space-between;
  align-items: center;
}
.choose-box .choose-box-item .checkbox-item,
.choose-box .choose-box-item .columns-item,
.choose-box .choose-box-item .action-item {
  overflow: hidden;
}
.choose-box .choose-box-item .columns-item .label {
  display: inline-block;
  margin-right: 10px;
  color: darkgrey;
  float: left;
}
.choose-box .choose-box-item .columns-item .value {
  display: inline-block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.choose-box .choose-box-item .action-item {
  overflow: hidden;
}
.choose-box .choose-box-item .action-item .ant-btn,
.choose-box .choose-box-item .action-item a {
  padding: 0 !important;
  margin-right: 15px;
}
.choose-box .choose-box-item .action-item .ant-btn:last-child,
.choose-box .choose-box-item .action-item a:last-child {
  margin-right: 0;
}
.pagination-box {
  padding: 10px 0;
  text-align: right;
}
.ant-modal {
  max-width: calc(100vw) !important;
}
.text-gray {
  color: #646566 !important;
}
.fs-12 {
  font-size: 12px !important;
}
.p-0 {
  padding: 0 !important;
}
.ant-form-large .ant-form-item-label > label {
  font-size: 16px;
}
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #979797;
}
*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.model-box .model-list {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  height: 830px;
  overflow: auto;
}
.model-box .model-list .modal-list-item {
  text-align: center;
}
.model-box .model-list .modal-list-item .block {
  margin-bottom: 15px;
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.model-box .model-list .modal-list-item .block img {
  max-width: 100%;
}
.model-box .model-list .modal-list-item .block.on,
.model-box .model-list .modal-list-item .block:hover {
  border: 1px solid skyblue;
  background-color: #f6faff;
}
.model-box .model-canvas-box {
  width: 100%;
  min-height: 800px;
  padding: 15px;
  background-color: #eff3fc;
  position: relative;
}
.model-box .model-canvas-box .canvas {
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 15px;
}
.model-box .model-canvas-box #frame {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 800px;
  overflow: hidden;
  background-color: #fff;
}
.model-box .model-canvas-box #container {
  position: relative;
}
.model-box .model-canvas-box .wrapper {
  width: 0;
  height: 0;
  position: relative;
  /* Origin of coordinates for lines, and scrolled content (i.e. not `absolute`) */
}
.model-box .model-tags-list {
  position: absolute;
  top: 20px;
  right: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
}
.model-box .model-tags-list .icon {
  margin-bottom: 20px;
  cursor: pointer;
}
.model-box .model-tags-list .icon:last-child {
  margin-bottom: 0;
}
.model-box .model-tags-list .choose-list {
  background-color: #f8f8f8;
  position: absolute;
  width: 200px;
  top: 0;
  left: -200px;
}
.model-box .model-tags-list .choose-list .ant-list-header {
  padding: 0;
  border: none;
}
.model-box .model-tags-list .choose-list .ant-list-header .header {
  background-color: #758595;
  color: #fff;
  padding: 8px 15px;
  font-size: 12px;
}
.model-box .model-tags-list .choose-list .ant-list-header .search-box,
.model-box .model-tags-list .choose-list .ant-list-header .btn-box {
  padding: 8px 15px;
}
.model-box .model-tags-list .choose-list .ant-list-items {
  padding-bottom: 15px;
  max-height: 500px;
  overflow: auto;
}
.model-box .model-tags-list .choose-list .ant-list-items .ant-list-item {
  cursor: pointer;
  font-size: 12px;
  color: #333;
  padding: 8px 15px;
}
.model-box .model-tags-list .choose-list .ant-list-items .ant-list-item .ant-collapse {
  width: 100%;
  border: none;
  font-size: 12px;
}
.model-box .model-tags-list .choose-list .ant-list-items .ant-list-item .ant-collapse .ant-collapse-item {
  border: none;
}
.model-box .model-tags-list .choose-list .ant-list-items .ant-list-item .ant-collapse .ant-collapse-item .ant-collapse-header,
.model-box .model-tags-list .choose-list .ant-list-items .ant-list-item .ant-collapse .ant-collapse-item .ant-collapse-content,
.model-box .model-tags-list .choose-list .ant-list-items .ant-list-item .ant-collapse .ant-collapse-item .ant-collapse-content-box {
  border: none;
  padding: 0;
  background: transparent;
}
.modules-layout .basic {
  border: 1px solid #7e8d9c;
  padding: 15px;
  border-radius: 5px;
  width: 220px;
  background-color: #fff;
  position: relative;
  font-size: 12px;
}
.modules-layout .basic .close,
.modules-layout .basic .plus {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  background: #fff;
  cursor: pointer;
  display: none;
}
.modules-layout .basic .plus {
  right: -10px;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin: auto;
}
.modules-layout .basic:hover .close,
.modules-layout .basic:hover .plus {
  display: block;
}
.modules-layout .basic .ant-form-item:last-child {
  margin: 0;
}
.modules-layout .basic .ant-form-item .ant-input-number,
.modules-layout .basic .ant-form-item .ant-picker {
  width: 100%;
}
.modules-layout .basic .ant-form-item .ant-form-item-control {
  display: flex;
  align-items: center;
}
.modules-layout .basic .ant-form-item .ant-form-item-control > .ant-form-item-control-input {
  width: 100%;
}
.modules-layout .basic .ant-form-item .ant-form-item-control.ant-col-16 {
  flex: auto;
  flex-direction: unset;
}
.modules-layout .basic .ant-form-item .ant-form-item-control.ant-col-16 > .ant-form-item-control-input {
  width: 80%;
  display: inline-block;
  float: left;
}
.modules-layout .basic .ant-form-item .ant-form-item-control.ant-col-16 > .ant-form-item-extra {
  display: inline-block;
  margin-left: 5px;
}
.modules-layout .level1 {
  width: 640px;
  overflow: hidden;
}
.td-vtop td {
  vertical-align: top;
}
.not-select {
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  /*IE10*/
  /*早期浏览器*/
  user-select: none;
}
.ant-table-cell .ant-collapse {
  font-size: 12px;
}
.ant-table-cell .ant-collapse .ant-collapse-header {
  padding: 0;
  display: inline-block;
}
.ant-table-cell .ant-collapse .ant-collapse-header > div {
  display: inline-block;
}
.ant-table-cell .ant-collapse .ant-collapse-header .title {
  float: left;
}
