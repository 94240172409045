body,
html,
.ant-layout {
  position: relative;
  background-color: #eff3fc;
}
.login-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}
.login-cont .copyright > p {
  font-size: 12px;
  font-weight: 400;
  font-family: SourceHanSansSC-Regular, SourceHanSansSC;
  color: #333;
  text-decoration: none;
}
.login-cont .copyright > p a {
  color: #333;
  text-decoration: none;
}
.login-cont .login-sign,
.login-cont .login-register {
  width: 300px;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 40px;
}
.login-cont .login-sign .title,
.login-cont .login-register .title {
  margin-bottom: 45px;
}
.login-cont .login-sign .title h1,
.login-cont .login-register .title h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 32px;
  margin-top: 0;
}
.login-cont .login-sign .title p,
.login-cont .login-register .title p {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  margin: 5px 0 0;
}
.login-cont .login-sign .ant-form .ant-form-item,
.login-cont .login-register .ant-form .ant-form-item {
  margin-bottom: 20px;
}
.login-cont .login-sign .ant-form .ant-form-item .ant-input-affix-wrapper,
.login-cont .login-register .ant-form .ant-form-item .ant-input-affix-wrapper {
  border-radius: 5px;
}
.login-cont .login-sign .orther,
.login-cont .login-register .orther {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999;
  margin-top: 15px;
}
.login-cont .login-sign .form-agreement,
.login-cont .login-register .form-agreement {
  font-size: 12px;
  font-weight: 400;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  margin-top: 76px;
  text-align: center;
}
