.container {
  position: relative;
  width: 100%;
  height: 100%;
}
.container > .download {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
}
.container > .download .intro {
  position: relative;
  padding: 0 60px;
  width: 50%;
  height: 100%;
  flex: 1 0 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.container > .download .intro > .body .logo {
  margin-bottom: 20px;
}
.container > .download .intro > .body .logo img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.container > .download .intro > .body .title-box {
  margin-bottom: 60px;
}
.container > .download .intro > .body .title-box .title {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 10px;
}
.container > .download .intro > .body .title-box .subtitle {
  font-size: 16px;
}
.container > .download .intro > .body .version-list {
  margin-bottom: 60px;
}
.container > .download .intro > .body .version-list-item {
  font-size: 16px;
  color: #444;
  margin-bottom: 8px;
}
.container > .download .intro > .body .version-list-item > .day {
  margin-left: 15px;
  color: #999;
}
.container > .download .intro > .body .download-list {
  display: flex;
  align-items: center;
}
.container > .download .intro > .body .download-list-item {
  margin-right: 15px;
  width: 100px;
  background-color: #f8f8f8;
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}
.container > .download .intro > .body .download-list-item:hover .icon .front {
  opacity: 0;
  transform: scale(0.4);
}
.container > .download .intro > .body .download-list-item:hover .icon .hover {
  opacity: 0.9;
  transform: scale(1);
}
.container > .download .intro > .body .download-list-item .icon {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.container > .download .intro > .body .download-list-item .icon img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  transition: all 0.3s ease 0s;
  position: absolute;
}
.container > .download .intro > .body .download-list-item .icon > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 27px;
  font-size: 16px;
  transition: all 0.3s ease 0s;
}
.container > .download .intro > .body .download-list-item .icon .front {
  opacity: 1;
  transform: scale(1);
}
.container > .download .intro > .body .download-list-item .icon .hover {
  opacity: 0;
  transform: scale(1.333, 1.333);
}
.container > .download .intro > .body .download-list-item .text {
  font-size: 16px;
  color: #333;
}
.container > .download .intro > .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  padding-left: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.container > .download .intro > .footer .copyright {
  font-size: 12px;
  color: #999;
}
.container > .download .images {
  position: relative;
  padding: 30px 60px;
  width: 50%;
  height: 100%;
  flex: 1 0 50%;
  overflow: hidden;
  overflow-y: auto;
}
.container > .download .images .title-box {
  margin-bottom: 30px;
}
.container > .download .images .title-box .title {
  font-size: 22px;
  padding-left: 15px;
  border-left: 4px solid #007aff;
}
.container > .download .images .name-list-item {
  margin-bottom: 15px;
}
.container > .download .images .name-list-item .name {
  width: 100px;
  margin-right: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}
.container > .download .images .name-list-item .code {
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
}
.container > .download .images .name-list-item .code .ant-typography {
  margin: 0;
}
